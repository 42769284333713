.viny_section_two {
    background-color: $primary-color;
    height: 31.25rem;
    margin-top: 10rem;
    margin-bottom: 12.5rem;
}
.viny_section_two ul {
    display: flex;
    margin-top: -154px;
    flex-wrap: wrap;
    width: 616px;

}
.viny_section_two ul li {
    width: 50%;
    padding: 0 .9375rem;    
}
.viny_section_two ul li:nth-child(1) {
    margin-top: 3.125rem;
}
.viny_section_two ul li:nth-child(4) {
    margin-top: -3.125rem;
}
.viny_section_two .card-panel {
    min-height: 17.375rem;
    box-shadow: 0 .5rem 1.875rem 0 rgba(0, 0, 0, 0.05);
    border-radius: .625rem;
    padding-top: 2.875rem;
    padding: 1.9375rem .9375rem .9375rem; 
    margin: .9375rem 0;   
}
.viny_section_two .img {
    width: 9.375rem;
    height: 9.375rem;
    background: #F3F4F6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 9.375rem;
}
.viny_section_two .card-panel h5 {
    font-size: 1.4375rem;
    font-weight: 400;
    margin-top: 1.0625rem;
    margin-bottom: .3125rem;
    padding: 0 1.25rem 0;
}
.viny_section_two .card-panel p {
    margin-top: 0;
    margin-bottom: 1.25rem;
} 

.viny_section_two h2 {
    color: #fff;
    margin-top: 5.75rem;
    margin-left: 5rem;
}
.viny_section_two .car {
    margin-top: 1rem;
    display: inline-block;
    width: 65rem;
}
@media ( max-width: 48em ) {
    .viny_box {
        margin-left: 4rem;
    }
    .viny_section_two {
        height: auto;
        margin-top: 1rem;
        margin-left: auto;
        left: auto;
        right: auto;
        margin-bottom: 10rem;
    }
    .viny_section_two .car_img {
        margin-left: 0;
        width: 52rem;
        margin-bottom: -100px;
    }
    .viny_section_two ul {
        margin-top: 1rem;
    }
    .viny_section_two ul li:nth-child(2) {
        margin-bottom: 0;
    }
    .viny_section_two ul li:nth-child(3) {
        margin-top: 0;
    }
}
@media(max-width: 411px) {
    .viny_section_two h2 {
        margin-left: 1rem;
    }
    .viny_box {
        margin-left: 0;
    }
    .viny_section_two {
        height: auto;
        margin-top: 1rem;
        margin-left: auto;
        left: auto;
        right: auto;
        margin-bottom: 10rem;
    }
    .viny_section_two ul li {
        display: block;
        width: 26.625rem;
    }    
    .viny_section_two ul li:nth-child(4) {
        margin-top: 0;
    }
    .viny_section_two ul li:nth-child(1) {
        margin-top: 0;
    }
    .viny_section_two .car_img {
        margin-left: 0;
        width: 28rem;
        margin-bottom: -3.125rem;
    }
}
.madeeasy_step {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 0;
}

.madeeasy_step li {
    flex: 0 0 100%;
    max-width: 33.33%;
    text-align: center;
}
.madeeasy_step li:first-child {
    text-align: left;
}
.madeeasy_step li:first-child .box-step { 
    margin-right: auto !important;
    margin-left: 0;
}
.madeeasy_step li:last-child {
    text-align: right;
}
.madeeasy_step li:last-child .box-step { 
    margin-left: auto !important;
    margin-right: 0;
}
.madeeasy_step .step-img {
    border-radius: .3125rem;
    overflow: hidden;
    display: flex;
}
.madeeasy_step .step-img img {
    border-radius: .3125rem;
}
.madeeasy_step .box-step {
    width: 282px;
    height: 200px;
    background:transparent;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
}
.madeeasy_step .number {
    background: $primary-color;
    color: #fff;
    border-radius: 50%;
    width: 3.4375rem;
    height: 3.4375rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.375rem;
    font-weight: 600;
    top: 50%;
    left: -1.5625rem;
    transform: translateY(-50%);
}
.madeeasy_step .demo-icon {
    background: #fff;
    color: $secondary-color;
    border-radius: 50%;
    width: 2.8125rem;
    height: 2.8125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    font-size: 1.125rem;
    font-weight: 600;
    top: 50%;
    right: -8rem;
    transform: translateY(-50%);
    box-shadow: 0 .3125rem 1.875rem rgba(0, 0, 0, 8%);
}

@media(max-width: 48em) {
    .madeeasy_step li { 
        max-width: 100%;
        margin-bottom: 5rem;
    }
    .madeeasy_step li:first-child .box-step, .madeeasy_step li:last-child .box-step {
        margin: 0 auto;
    }
    .madeeasy_step li .box-step {
        margin: 0 auto;
    }
    .madeeasy_step .number {
        width: 2.5rem;
        height: 2.5rem;
        left: 0;
        font-size: 1rem;
        right: 0;
        top: 0px;
        margin: 0 auto;
    }
    .madeeasy_step .demo-icon {
        width: 2rem;
        height: 2rem;
        right: 0;
        left: 0;
        margin: 0 auto;
        bottom: -.5rem;
        top: auto;
        transform: rotate(90deg);
    }
}

@media(max-width: 411px) {
    .madeeasy_step li {
        max-width: 100%;
        margin-bottom: 5rem;
    }
    .madeeasy_step li:first-child .box-step, .madeeasy_step li:last-child .box-step {
        margin: 0 auto;
    }
    .madeeasy_step li .box-step {
        margin: 0 auto;
    }
    .madeeasy_step .number {
        left: 0;
        right: 0;
        top: 0;
        margin: 0 auto;
    }
    .madeeasy_step .demo-icon {
        width: 2rem;
        height: 2rem;
        right: 0;
        left: 0;
        margin: 0 auto;
        bottom: -.5rem;
        top: auto;
        transform: rotate(90deg);
    }
}

