 .usp_container {
    margin-top: 2em !important;
    // display: -webkit-inline-box;
    width: 100%;
}

.usp_container .card-panel {
    min-height: 22.5rem;
    box-shadow: 0 .5rem 1.875rem 0 rgba(0, 0, 0, 0.05);
    border-radius: .5rem;
    padding-top: 2.875rem;
    padding: 1.875rem 1.875rem .625rem;   
}

.usp_container .card-panel .img {
    width: 6.875rem;
    height: 6.875rem;
    background: #F3F4F6;
    color: lighten($primary-color, 0%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    border-radius: 9.375rem;
}
.usp_container .card-panel .usp-title {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 600;
    margin-top: 1rem;
    margin-bottom: .75rem;
    padding: 0 1.25rem 0;
}
.usp_container .card-panel p {
    margin-top: 0;
    margin-bottom: 1rem;
}
