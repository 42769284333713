.category-header-form-container {
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color: white;
    padding: 3rem 1rem 2rem 1rem;
}

.category-header-form {
    margin-left: 1rem;
}

.faqtitle {
    margin-top: 1em;
    font-weight: 400;
    color: lighten($primary-color, 10%);
    font-size: 2em;
    line-height: 1.125em;
    text-transform: uppercase;
}
.faqtitle strong {
    font-weight: 700;
}
.image-wrap-body a {
    margin-top: 1rem;
}

.download-container {
    margin: 3rem 0;
}
.download-link h3 {
    font-weight: 600;
}
.download-link a {
    color: blue;
    font-size: 1rem;   
}
.download-link p {
   font-size: 2rem;
   line-height: 2.5rem;
}
.quote_box, .guarantee_box {
    position: relative;
    background-color: $primary-color;
    min-height: 20.5rem;
    margin-top: 2rem;
    margin-bottom: 0px;
}

.quote_box p, .guarantee_box p {
    position: relative;
    z-index: 2;
    font-size: 2.25rem;
    text-align: center;
    color: #fff !important;
    width: 100%;
    margin: 0 auto;
    font-weight: 400;
    line-height: 3.5rem;
}
.product-price {
    font-size: 2.5rem;
    font-weight: 600;
    color: #c82027;
}