.table-wrapper {
    background-color: #fff;
    border: .0625rem solid $primary-color;
    border-radius: .625rem;
    overflow-x: scroll;
    padding-bottom: 2rem;
    margin-top: 2rem;
    margin-bottom: 3rem;
}
.table-wrapper .table-header {
    background-color: $secondary-color;
    color: white;
    margin:0;
    padding: 1.5rem 0 1.5rem 0;
    font-size: 2rem;
    font-weight: 500;
}
   
.table-wrapper::-webkit-scrollbar-track {
    background-color: lighten($primary-color, 10%);
}
   
.table-wrapper::-webkit-scrollbar-thumb {
    border-radius: .5rem;
    background-color: darken($secondary-color, 0%);
    box-shadow: inset 0 0 .375rem rgba(0, 0, 0, 0.5);
}
.table-wrapper::-webkit-scrollbar {
    width: 1rem;
}

table {
    width: 100%;
    table-layout: auto;
    font-size: 1.5rem;
    font-weight: 400;
}

thead {
    background-color: $primary-color;
    color: white;
}

th {
    padding: 1rem 0 1rem 1.875rem;
    font-weight: 500;
    text-align: center;
}

td {
    padding: .75rem 0 .75rem 1.875rem;
    text-align: center;
}

table.striped>tbody>tr:nth-child(odd) {
    background-color: #fff;
}

table.striped>tbody>tr:nth-child(even) {
    background-color: #f3f4f6;
}
td a {
    color: blue;
}
td strike {
    color: #737373;
}

table .round {
    background: $secondary-color;
    width: 3.125rem;
    height: 3.125rem;
    border-radius: 6.25rem;
    display: inline-block;
    text-align: center;
    line-height: 3.125rem;
    color: #fff;
    font-weight: 600;
    font-size: 1.25rem;
}

table td.item div {
    display: block;
    font-size: 1.125rem;
    line-height: 1.875rem;
    color: #737373;
}

.basket-total {
    color: black;
    background-color: #ffe066;
    padding: .25rem;
}

table td .update {
    text-align: center;
    font-size: 1rem;
    font-weight:500;
    margin-top: -1rem;
}
.update a:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 25.6875em) {
    table thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    table tr {
      border-bottom: 3px solid #ddd;
      display: block;
    }
    
    table td {
      border-bottom: 1px solid #ddd;
      display: block;
      text-align: left;
    }
    .qty-box {
        display:inline-block;
        text-align: right;        
    }    
    table td::before {
      content: attr(data-label);
      padding-right: 2em;
      font-weight: 500;
    }
}

@media ( min-width: 37.5625em ) and ( max-width: 64em ) {
    .table-wrapper::-webkit-scrollbar {
        width: none;
    }
    .table-summary {
        font-size: .875rem;
    }
}
