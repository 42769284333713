.product-table, 
.bl-letter-table, 
.marquee-content-table {
    font-size: 1.75rem;
}
.product-table td:before, 
.bl-letter-table td:before, 
.basket-table td:before, 
.checkout-table td:before, 
.marquee-content-table td:before {
    float: none !important;
}
.product-table tbody tr td:nth-child(2), 
.bl-letter-table tbody tr td:nth-child(1), 
.basket-table tbody tr td:nth-child(2), 
.checkout-table tbody tr td:nth-child(1), 
.marquee-content-table tbody tr td:nth-child(1) {
    text-align: left;
}
.product-table tbody tr th:last-child, 
.bl-letter-table tbody tr th:last-child, 
.basket-table tbody tr th:last-child, 
.checkout-table tbody tr th:last-child, 
.marquee-content-table tbody tr th:last-child {
    padding-right: .5rem;
}
