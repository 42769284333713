.collapsible {
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important; box-shadow: none !important;
}

.collapsible li {
    margin-bottom: 1.875rem;
    background-color: #fff;
    border-radius: .5rem;
}
.collapsible .collapsible-header {
    background-color: transparent !important;
    border: none !important;
    margin: 0 !important;
    box-shadow: none !important;
    font-size: 1.5rem;
    font-weight: 600;
    color: $primary-color;
    padding-right: 3.75rem;

}
.collapsible .active p {
    margin-top: 0;
}
.collapsible .collapsible-body { 
    padding-left: 3.75rem;
    padding-top: 0;
    border-bottom: none;
}
.collapsible .collapsible-body p:last-child {
    margin-bottom: 0;
}
.collapsible .red_icon { 
    color: $secondary-color;
    font-size: 1.125rem;
    margin-top: .43;
}
.collapsible .collapsible-header {
    position: relative;
}
.collapsible .collapsible-header:after { 
    position: absolute;
    content: "+";
    font-size: 2.5rem;
    right: 1.25rem;
    top: .125rem;
    font-weight: 400;
    color: $primary-color;
}
 .collapsible .active .collapsible-header:after { 
    position: absolute;
    content: "-";
}