ul.see-also li a:hover, ul.see-also li a:focus, .list_breadcrumb a:hover, .list_breadcrumb a:focus {
    color:#0066cc !important;
    text-decoration: underline;
    cursor: pointer;
}
.breadcrumb:before {
    content: "" !important;
    position:relative;
    top: .375rem;
    margin-left: 0;
    margin-right: 0;
}

img.breadcrumbs {
    position :relative;
    top: .5rem;
    margin-left: .25rem;
    margin-right: .25rem;
}
.breadcrumb, .list_breadcrumb:last-child {
    color: darken($secondary-color, 0%) !important;
    font-size: 1.5rem;
    font-weight: 500;
}
ul.see-also li {
    color: darken($primary-color, 0%);
    font-size: 1rem;
    font-weight: 500;
    margin-top: .5rem;
    margin-right: .5rem;
    display: inline-block;
}

ul.see-also li a {
    color: darken($primary-color, 0%);
}

ul.see-also li:first-child  {
    color: darken($primary-color, 0%);
    font-weight: 700;
}

