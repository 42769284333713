.howtoinstall_box ul {
    padding-left: 4.375rem;
}
.howtoinstall_box ul li {
    position: relative;
    display: inline-block;
}
.howtoinstall_box ul li:before {
    content: "";
    border-left: .125rem dashed $primary-color;
    position: absolute;
    height: calc(100% + 2.5rem);
    left: -2.875rem
}
.howtoinstall_box ul li .number_round {
    width: 3.125rem;
    height: 3.125rem;
    position: absolute;
    background: $primary-color;
    line-height: 3.125rem;
    color:#fff;
    font-size: .9375rem;
    text-align: center;
    font-weight: 600;
    left: -4.375rem;
    border-radius: 6.25rem;
}